<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <EvacuationTable
          v-if="tableData"
          :evacuationid="evacuationid"
          :tabledata="tableData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import EvacuationTable from "./tables/EvacuationTable.vue";
export default {
  name: "Evacuation",
  components: {
    EvacuationTable,
  },
  data() {
    return {
      msg: "Evacuation",
      evacueeident: "",
      errorMessage: "",
      evacuationid: parseInt(this.$route.params.evacuationID),
    };
  },
  computed: {
    ...mapState({
      tableData: (state) => state.evacuees.evacuees,
    }),
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData(e) {
      const payload = { ID: this.evacuationid };
      this.$store.dispatch("evacuees/updateDataByID", payload);
    },
  },
};
</script>

<style lang="scss" scoped></style>
